import { Alert } from '@material-ui/lab';
import { Buffer } from 'buffer';
import { Button, Collapse, LinearProgress, Paper, TextField } from '@material-ui/core';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SbxTableData from '../../components/SbxDisplayData';
import SbxPaperCompanyFolio from '../../components/SbxPaperCompanyFolio';
import SbxSectionContainer from '../../components/SbxSectionContainer';
import SbxTitle from '../../components/SbxTitle';
import { getHomePath, getReportLicenseKey, httpConfig, useQueryParams } from '../../utils/general';
import { getCFDiSummary, getCustomerSummary, getSaleSumary } from '../../utils/sumary';

const loadData = debounce((params, callback, callbackError) => {
    axios.get(`rest/api/v4/sale/cfdi/cfdidocuments?ID=${params.CFDiDocumentID}&mask_fields=CFDiFileID,OriginalString,OriginalStringSAT,StampUUID`, httpConfig())
        .then(respCFDiDocument => {
            const CFDiFileID = respCFDiDocument.data.elements[0].CFDiFileID;
            const gets = [
                axios.get(`/rest/api/v4/sale/cfdi/summary?saledocument_id=${params.SaleDocumentID}&customer_id=${params.CustomerID}&report_template=true&cfdi=true`, httpConfig()),
                axios.get(`rest/api/v4/sale/cfdi/cfdifiles?ID=${CFDiFileID}&mask_fields=ID,XML`, httpConfig())];
            Promise.all(gets)
                .then(responses => callback(
                    responses[0].data,
                    responses[1].data.elements.length > 0 ? responses[1].data.elements[0] : null,
                    respCFDiDocument.data.elements[0]));
        })
        .catch(error => callbackError(error));

    // carga de las fuentes para el reporte en pdf
    const Stimulsoft = window.Stimulsoft;
    // Licencia del reporteador
    Stimulsoft.Base.StiLicense.key = getReportLicenseKey();
    //Stimulsoft.Base.StiFontCollection.addOpentypeFontFileAsync(() => { }, `${getHomePath()}/fonts/lucon.ttf`, 'Lucida Console');
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFileAsync(() => { }, `${getHomePath()}/fonts/arialbd.ttf`, 'Arial', Stimulsoft.System.Drawing.FontStyle.Bold);
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFileAsync(() => { }, `${getHomePath()}/fonts/ariali.ttf`, 'Arial', Stimulsoft.System.Drawing.FontStyle.Italic);
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFileAsync(() => { }, `${getHomePath()}/fonts/arial.ttf`, 'Arial');
}, 100);

async function printReport(data) {
    const Stimulsoft = window.Stimulsoft;

    // Creating a new report object
    const report = Stimulsoft.Report.StiReport.createNewReport();

    // Loading a report template (MRT) into the report object
    report.load(data.Template);

    // Carga del xml
    const blob = new Blob([data.XML], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const dbCFDi = report.dictionary.databases.getByName("CFDi");
    dbCFDi.pathSchema = "";
    dbCFDi.pathData = url;

    // Variables del reporte
    let variable = report.dictionary.variables.getByName('OriginalString');

    if (variable)
        variable.value = data.OriginalString;

    variable = report.dictionary.variables.getByName('OriginalStringSAT');

    if (variable)
        variable.value = data.OriginalStringSAT;

    return report;
}

const Done = () => {
    const navigate = useNavigate();
    const queryParams = useQueryParams();
    const CFDiDocumentID = queryParams.get('cfdidocument_id');
    const SaleDocumentID = queryParams.get('saledocument_id');
    const CustomerID = queryParams.get('customer_id');
    const mustSendMail = queryParams.get('send_mail');
    const [company, setCompany] = useState(null);
    const [state, setState] = useState(null);
    const [working, setWorking] = useState(false);
    const [mail, setMail] = useState({
        value: '',
        isValid: true,
        buttonChecked: false
    });
    const [notification, setNotificaction] = useState({
        open: false,
        message: '',
        severity: '',
    });

    const handleOnNotifyCompanyLoaded = (data) => {
        setCompany(data);
    };

    const handleChangeEmail = (e) => {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        setMail({ ...mail, value: e.target.value, isValid: e.target.value === '' || e.target.value.match(pattern) })
    };

    const sendMail = (successMsg, toAddress) => {
        setWorking(true);

        printReport(state)
            .then((report) => {
                // Dibujado del reporte
                report.renderAsync(() => {
                    // Exporting the report to PDF
                    report.exportDocumentAsync((pdfData) => {
                        const payload = { ID: state.CFDiFileID, PDF: Buffer.from(pdfData).toString('base64') };
                        axios.put('rest/api/v4/sale/cfdi/cfdifiles?light_response=true', payload, httpConfig())
                            .then(() => {
                                axios.post('rest/api/v4/sale/cfdi/sendemailcfdidocument', { cfdidocument_id: CFDiDocumentID, email: toAddress ? toAddress : '' }, httpConfig())
                                    .then(() => {
                                        setWorking(false);
                                        setNotificaction({ open: true, message: successMsg, severity: 'success' });
                                    })
                                    .catch((error) => {
                                        setWorking(false);
                                        const msgError = error.response && error.response.data ? error.response.data.error : error.toString();
                                        setNotificaction({ open: true, message: msgError, severity: 'warning' });
                                    });
                            })
                            .catch((error) => {
                                setWorking(false);
                                const msgError = error.response && error.response.data ? error.response.data.error : error.toString();
                                setNotificaction({ open: true, message: msgError, severity: 'warning' });
                            });
                    }, window.Stimulsoft.Report.StiExportFormat.Pdf);
                });
            })
            .catch(error => {
                setWorking(false);
                setNotificaction({ open: true, message: `No se pudo generar el reporte. ${error.message}`, severity: 'warning' });
            });
    };

    const downloadPDF = () => {
        setWorking(true);
        printReport(state)
            .then((report) => {
                // Dibujado del reporte
                report.renderAsync(() => {
                    // Exporting the report to PDF
                    report.exportDocumentAsync((pdfData) => {
                        // Se actualiza central con el nuevo pdf generado
                        const payload = { ID: state.CFDiFileID, PDF: Buffer.from(pdfData).toString('base64') };
                        axios.put('rest/api/v4/sale/cfdi/cfdifiles?light_response=true', payload, httpConfig());
                        // Descarga del archivo
                        const fileName = `${state.StampUUID}.pdf`;
                        window.Stimulsoft.System.StiObject.saveAs(pdfData, fileName, 'application/pdf');
                        setWorking(false);
                    }, window.Stimulsoft.Report.StiExportFormat.Pdf);
                });
            })
            .catch(error => {
                setWorking(false);
                setNotificaction({ open: true, message: `No se pudo generar el reporte. ${error.message}`, severity: 'error' });
            });
    };

    const downloadXML = () => {
        const blob = new Blob([state.XML], { type: 'text/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${state.StampUUID}.xml`;
        link.click();
    };

    useEffect(() => {
        if (!company)
            return;

        loadData({ CFDiDocumentID, SaleDocumentID, CustomerID },
            (summaryData, cfdiFileData, cfdDocumentData) => {
                let base64ToString = Buffer.from(summaryData.Report__Template, "base64").toString();
                setState({
                    Folio: summaryData.SaleDocument__InvoiceKioskFolio,
                    CFDiFileID: cfdiFileData.ID,
                    Template: base64ToString,
                    XML: cfdiFileData.XML,
                    OriginalString: cfdDocumentData.OriginalString,
                    OriginalStringSAT: cfdDocumentData.OriginalStringSAT,
                    StampUUID: cfdDocumentData.StampUUID,
                    SaleSumary: getSaleSumary(summaryData),
                    CustomerSumary: getCustomerSummary(summaryData),
                    CFDiSummary: getCFDiSummary(summaryData)
                });
            },
            () => { navigate('/', { replace: true }) }
        );
        // eslint-disable-next-line
    }, [company]);

    useEffect(() => {
        if (!state || !state.Template)
            return;

        if (mustSendMail === 'true')
            sendMail('Su factura se generó correctamente y se envío por correo');
        // eslint-disable-next-line
    }, [state]);

    if (company && !state)
        return (
            <section>
                <LinearProgress />
            </section>
        );

    return (
        <>
            <SbxSectionContainer
                companyData={company}
                onNotifyCompanyLoaded={handleOnNotifyCompanyLoaded}
            >
                {company && state ?
                    <div className="d-flex-col f-grow-1" style={{ gap: '8px', maxWidth: '700px' }}>
                        <SbxPaperCompanyFolio company={company} folio={state.Folio} />

                        <Paper elevation={1} style={{ padding: '24px' }}>
                            <div className="d-flex-col" style={{ gap: '8px' }}>
                                {notification.open &&
                                    <Alert onClose={() => setNotificaction({ ...notification, open: false })} severity={notification.severity}>
                                        {notification.message}
                                    </Alert>
                                }

                                <div className="d-flex-row" style={{ gap: '8px' }}>
                                    <div className="f-grow-1">
                                        <Button
                                            style={{ height: '48px' }}
                                            fullWidth
                                            disabled={working}
                                            variant="contained"
                                            size="medium"
                                            onClick={(e) => navigate('/')}
                                        >
                                            Volver al inicio
                                        </Button>
                                    </div>
                                    <div className="f-grow-1">
                                        <Button
                                            style={{ height: '48px' }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            disabled={working}
                                            onClick={(e) => { setMail({ ...mail, buttonChecked: !mail.buttonChecked }) }}
                                        >
                                            Reenviar correo
                                        </Button>
                                    </div>
                                    <div className="f-grow-1">
                                        <Button
                                            style={{ height: '48px' }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            disabled={working}
                                            onClick={(e) => downloadXML()}
                                        >
                                            Descargar XML
                                        </Button>
                                    </div>
                                    <div className="f-grow-1">
                                        <Button
                                            style={{ height: '48px' }}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            disabled={working}
                                            onClick={(e) => downloadPDF()}
                                        >
                                            Descargar PDF
                                        </Button>
                                    </div>
                                </div>

                                <Collapse in={mail.buttonChecked} timeout="auto" unmountOnExit>
                                    <div className="d-flex-row " style={{ alignItems: 'flex-start', justifyContent: 'center', marginTop: 8, gap: 8 }}>
                                        <TextField
                                            style={{ flexGrow: 8 }}
                                            name="Email"
                                            label="Correo electrónico"
                                            variant="outlined"
                                            value={mail.value ? mail.value : ''}
                                            onChange={handleChangeEmail}
                                            error={!mail.isValid}
                                            helperText={!mail.isValid ? 'Correo inválido!' : ''}
                                            inputProps={{ inputMode: 'email' }}
                                        />

                                        <Button
                                            style={{ flexGrow: 1, height: '56px' }}
                                            variant="contained"
                                            color="secondary"
                                            size="medium"
                                            disabled={working || mail.value === '' || !mail.isValid}
                                            onClick={(e) => sendMail('Correo enviado exitosamente', mail.value)}
                                        >
                                            Enviar
                                        </Button>
                                    </div>
                                </Collapse>
                            </div>
                        </Paper>

                        <Paper elevation={1} style={{ padding: '24px' }}>
                            <div className="d-flex-col" style={{ gap: '16px' }}>
                                <SbxTitle title='Datos del CFDi' />
                                <SbxTableData data={state.CFDiSummary} />
                            </div>
                        </Paper>

                        <Paper elevation={1} style={{ padding: '24px' }}>
                            <div className="d-flex-col" style={{ gap: '16px' }}>
                                <SbxTitle title='Datos de la Venta' />
                                <SbxTableData data={state.SaleSumary} />
                            </div>
                        </Paper>

                        <Paper elevation={1} style={{ padding: '24px' }}>
                            <div className="d-flex-col" style={{ gap: '16px' }}>
                                <SbxTitle title='Datos del Cliente' />
                                <SbxTableData data={state.CustomerSumary} />
                            </div>
                        </Paper>
                    </div>
                    : null
                }
            </SbxSectionContainer>
        </>
    );
};

export default Done;